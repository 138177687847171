<template>
  <div class="gas">
    <!-- floating button [for add] -->
    <v-btn
      fab
      absolute
      class="plusButton"
      right
      bottom
      color="red accent-2"
      @click="addDialog = !addDialog"
    >
      <v-icon color="#fff">mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="addDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add GAS
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="addCoe.price" label="Price"></v-text-field>
          <v-select
            v-model="addCoe.trend"
            :items="trendList"
            item-value="id"
            item-text="name"
            label="Trend"
          >
          </v-select>

          <v-dialog
            ref="dialog"
            v-model="gasDateModal"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="addCoe.date"
                label="COE Date"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker :min="today" v-model="addGas.date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="gasDateModal = false"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doAddCOE()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="gasList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr v-if="row.item.A != null">
          <td>{{ row.item.name }}</td>
          <td>{{ row.item.subName }}</td>
          <td>
            ${{ row.item.A.price }} <b>{{ row.item.A.trend }}</b>
          </td>
          <td>
            ${{ row.item.B.price }} <b>{{ row.item.B.trend }}</b>
          </td>
          <td>
            ${{ row.item.C.price }} <b>{{ row.item.C.trend }}</b>
          </td>
          <td>
            ${{ row.item.D.price }} <b>{{ row.item.D.trend }}</b>
          </td>
          <td>
            ${{ row.item.E.price }} <b>{{ row.item.E.trend }}</b>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10000"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import getAxios from "../../../services/axios-get";
import postAxios from "../../../services/axios-post";

Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      bumperSearch: "",
      bumperStatus: "",
      productURL: "",
      token: "",
      pageCount: 0,
      addDialog: false,
      deleteDialog: false,
      gasDateModal: false,
      todayDate: "",
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Date", value: "name" },
        { text: "Round", value: "subName" },
        { text: "Cat A", value: "A" },
        { text: "Cat B", value: "B" },
        { text: "Cat C", value: "C" },
        { text: "Cat D", value: "D" },
        { text: "Cat E", value: "E" },
      ],
      gasList: [],
      trendList: [
        { id: "up", name: "up" },
        { id: "down", name: "down" },
      ],
      addCoe: {
        code: "",
        date: "",
        price: 0,
        trend: "",
      },
    };
  },
  mounted() {
    this.fetchCredentials();
    this.fetchGasList();
    this.fetchCoeCategory();
  },
  methods: {
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchGasList();
    },
    async doAddCOE() {
      const self = this;
      try {
        const params = {
          date: self.addCoe.date,
          price: self.addCoe.price,
          trend: self.addCoe.trend,
          status: "ACTIVE",
        };
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/addCoePriceRecord`,
          params,
          self.token
        )
          .then(res => {
            if (res.data) {
              self.addDialog = false;
              self.fetchGasList();
              self.showAlert("success", "New COE Added!");
            }
          })
          .catch(e => {
            self.showAlert("error", e);
          });
      } catch (e) {
        console.error(e);
      }
    },
    delButtonClick(item) {
      this.delCarBidRecord.id = item.id;
      this.delCarBidRecord.name = item.productTitle;
      this.deleteDialog = true;
    },
    fetchGasList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/gasPriceRecord`,
        params,
        self.token
      ).then(res => {
        if (res.data) {
          self.gasList = res.data.data.content[0].fieldList;
          console.log(JSON.stringify(self.gasList));
          self.pagination.noOfPage = res.data.data.totalPages;
        }
      });
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
  },
};
</script>
